export const BG_PRIMARY = '#25303D';
export const BG_PRIMARY_GRADIENT = '#16202b';
export const BG_SECONDARY = '#C3DFEF';

export const TEXT_PRIMARY = '#FFFFFF';
export const TEXT_SECONDARY = BG_PRIMARY;

export const BUTTON_COLOR_TEXT = '#000';

// Icon & Navigation
export const BG_NAVIGATION = `#fff`;
export const BG_NAVIGATION_ICON_COLOR = `#000`;

// DEFAULT STYLES
export const DEFAULT_BUTTON_PROPS = {
  _active: { borderColor: 'transparent' },
  _hover: { borderColor: 'transparent' },
  _focus: { borderColor: 'transparent' },
};
export const DEFAULT_OPTIONS_PROPS = {
  style: { color: 'black' },
};
